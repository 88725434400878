import axios from 'axios';

const BASE_URL = "https://almquran.ecnet.dev/website/"
// const BASE_URL = "http://localhost/alm-qruan-back-end/public/website/"
const config = {
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    }
}

export async function getChapters() {
    try {
        const response = await axios.get(BASE_URL + 'chapters');
        //console.log('response  ', response)
        return response.data;
    } catch (error) {
        console.log('error  ', error)
        return [];
    }
}


export async function getAllTags($params) {
    try {
        const response = await axios.post(BASE_URL + 'getAllTags', $params, config)
        console.log('response  ', response)
        return response.data;
    } catch (error) {
        console.log('error  ', error)
        return [];
    }
}

export async function getSuggestions() {
    try {
        const response = await axios.get(BASE_URL + 'suggestions');
        //console.log('response  ', response)
        return response.data;
    } catch (error) {
        console.log('error  ', error)
        return [];
    }
}

export async function getTags($params) {
    try {
        const response = await axios.post(BASE_URL + 'getTags', $params, config)
        console.log('response  ', response)
        return response.data;
    } catch (error) {
        console.log('error  ', error)
        return [];
    }
}

export async function getAllNotes($params) {
    try {
        const response = await axios.post(BASE_URL + 'getAllNotes', $params, config)
        console.log('response  ', response)
        return response.data;
    } catch (error) {
        console.log('error  ', error)
        return [];
    }
}

export async function addTag($params) {
    try {
        const response = await axios.post(BASE_URL + 'addTag', $params, config)
        console.log('response  ', response)
        return response.data;
    } catch (error) {
        console.log('error  ', error)
        return [];
    }
}

export async function addNotes($params) {
    try {
        const response = await axios.post(BASE_URL + 'addNotes', $params, config)
        console.log('response  ', response)
        return response.data;
    } catch (error) {
        console.log('error  ', error)
        return [];
    }
}

export async function getNotes($params) {
    try {
        const response = await axios.post(BASE_URL + 'getNotes', $params, config)
        console.log('response  ', response)
        return response.data;
    } catch (error) {
        console.log('error  ', error)
        return [];
    }
}

export async function getAyatsByChapterId($id) {
    try {
        const response = await axios.get(BASE_URL + 'chapter/' + $id);
        //console.log('response  ', response)
        return response.data;
    } catch (error) {
        console.log('error  ', error)
        return [];
    }
}

export async function searchByWord($word) {
    try {
        const response = await axios.get(BASE_URL + 'search?s=' + encodeURIComponent($word));
        console.log(encodeURIComponent($word))
        console.log('response  ', response)
        return response.data;
    } catch (error) {
        console.log('error  ', error)
        return [];
    }
}

export async function indexes() {
    try {
        const response = await axios.get(BASE_URL + 'indexes');
        console.log('response  ', response)
        return response.data;
    } catch (error) {
        console.log('error  ', error)
        return [];
    }
}

